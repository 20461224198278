import React, { useState, useEffect, useRef, useMemo } from "react"
import { Typography } from "components/typography"
import * as styles from "./style.module.scss"
import { navigate } from "gatsby"
import { Stack } from "@mui/material"
import { LazyLoadImage } from "react-lazy-load-image-component"
import { apis as API } from "@sog/sdk"
import { providerImage } from "components/survey/utils"
import Disclaimer from "../disclaimer"
import AppManager from "services/app-manager"
import { useStore } from "react-redux"
import { DataCollector, DataCollectorAction } from "@sog/sdk"
import * as Clickable from "components/clickable"
import { useAuth } from "state/AuthContext"
import RefinanceDialog from "./refinance-dialog"

const Offers = (props: any) => {
  const store = useStore<any>()

  const progress = props.location.state.progress
  const jsonInput = props.location.state.jsonInput
  const baseline = props.location.state.baseline
  const [results, setResults] = props.location.state.results ? useState(props.location.state.results) : useState({})
  const source = props.location.state.source ? props.location.state.source : "SURVEY"
  const [open, setOpen] = useState(false)
  const [indexView, setIndexView] = useState(0)

  const { userDetails } = useAuth()
  const isLoggedIn = React.useMemo(() => (!!userDetails), [userDetails])

  // console.log("progress", progress)
  // console.log("jsonInput", jsonInput)
  // console.log("baseline", baseline)
  // console.log("results", results)

  const callUpdateUserProducts = (properties: any) => {
    const timestamp = Math.floor(Date.now() / 1000)
    properties.map((property: any) => {
      property.timestamp = timestamp
    })
    API.updateUserProducts({ properties: properties }, results => { })
  }

  useEffect(() => {
    if (progress === 3) {
      API.getYourDealVsMarket({ survey_id: store.getState().survey.surveyId, properties: jsonInput["properties"] }, responseJson => {
        setResults(responseJson)
        if (Object.keys(responseJson).length > 0 && Math.max(responseJson.majorOffer.savings, responseJson.challengerOffer.savings) >= 1000) {
          setTimeout(() => {
            navigate("/home-loans/your-deal-vs-market", {
              state: {
                progress: progress + 1,
                jsonInput: jsonInput,
                results: responseJson,
              },
              replace: true,
            })
          }, 3000)
        }
      })
    } else if (progress === 5) {
      jsonInput && jsonInput["properties"] && callUpdateUserProducts(jsonInput["properties"])
      // displaying offers; retrieve them
      API.getHLOffers({ survey_id: store.getState().survey.surveyId, properties: jsonInput["properties"] }, results => {
        DataCollector.getInstance().addAction(DataCollectorAction.HL_SURVEY_CURRENT_DEAL_CONTINUED, { surveyId: store.getState().survey.surveyId, ...results })
        if (results && (results.offers.stayOffers.length > 0 || results.offers.goOffers.length > 0)) {
          // store user data in local storage
          const offer = {
            jsonInput: jsonInput,
            results: results,
            datetime: new Date().toLocaleDateString(),
          }
          localStorage.setItem("hl-survey-offer", JSON.stringify(offer))
          setResults(results)
        } else {
          // no results; navigate to "Thank You" page
          navigate("/home-loans/thank-you", {
            state: { ...props.location.state },
          })
        }
      })
    }
  }, [])

  const Congratulations = () => {
    const savings = Object.keys(results).length > 0 ? Math.max(results.majorOffer.savings, results.challengerOffer.savings) : null
    if (savings == null) {
      return null
    }
    if (savings >= 1000) {
      return (
        <Stack style={{ textAlign: "center" }}>
          <Typography.H3 comp="hl-survey">Congratulations!</Typography.H3>
          <div style={{ marginTop: "40px" }}>
            <LazyLoadImage className={styles.beneftisDivChild} src="/images/icon_save.png" style={{ width: "84px" }} alt="" />
            <Typography.P className={styles.beneftisDivChild} style={{ marginTop: "20px" }}>
              you could save
            </Typography.P>
            <Typography.H3 fontColor="#00afc5" className={styles.beneftisDivChild} comp="benefit">
              {"$" + Math.round(savings).toLocaleString()}
            </Typography.H3>
            <Typography.P className={styles.beneftisDivChild}>over 5 years</Typography.P>
          </div>
        </Stack>
      )
    } else {
      return (
        <Stack style={{ textAlign: "center" }}>
          <Typography.H3 comp="hl-survey">Congratulations!</Typography.H3>
          <LazyLoadImage className={styles.beneftisDivChild} style={{ marginTop: "30px", marginBottom: "25px", width: "60px", height: "60px" }} src="/images/icon_congratulations.png" alt="" />

          <Typography.P comp="hl-medium-tight">Based on our review of 2,500+ rates and offers in market, it looks like you’re already on a completive deal.</Typography.P>
          <br />
          <Typography.P comp="hl-medium-tight">However, the home loan market is constantly changing, so it’s important to monitor your deal to ensure it remains competitive.</Typography.P>

          {!isLoggedIn && (
            <Clickable.Text
              comp={3}
              style={{
                height: "56px",
                width: "100%",
                marginTop: "130px",
              }}
              onClick={() => {
                navigate("/home-loans/create-account", {
                  state: {
                    progress: progress + 1,
                    jsonInput: jsonInput,
                    results: null,
                  },
                })
              }}
            >
              Track your rate
            </Clickable.Text>
          )}
        </Stack>
      )
    }
  }

  const RevertRateDisclaimer = ({ interestRate }: { interestRate: number }) => {
    return (
      <Typography.P comp="hl-disclaimer" style={{ marginBlock: "30px" }}>
        * Given the variable rate that your fixed rate will revert to on expiry is unknown, we have assumed {(interestRate * 100).toFixed(2)}% p.a. based on RBA data. Check the rate with your bank before making any decisions.
      </Typography.P>
    )
  }

  const RateView = ({ rate, purpose, isDetailView }: { rate: any; purpose: string | null; isDetailView: boolean }) => {
    let introTerm = rate.product.introTerm
    if (introTerm) {
      let introTermUnit = introTerm.charAt(introTerm.length - 1)
      if (introTermUnit == "Y") {
        introTermUnit = "year"
      } else if (introTermUnit == "M") {
        introTermUnit = "month"
      }
      introTerm = introTerm.substring(1, introTerm.length - 1) + " " + introTermUnit
    }
    return (
      <Stack direction="row" style={{ marginBlock: "20px" }}>
        <Stack style={{ width: "50%" }}>
          {purpose && <Typography.P comp={isDetailView ? "small-bold" : "medium-bold"}>{purpose}</Typography.P>}
          {rate.product.isIntro === "Y" && (
            <Typography.P comp="x-small" fontColor="#202020" style={{ marginBottom: "5px" }}>
              {introTerm} intro rate
            </Typography.P>
          )}
          {!isDetailView && rate.alerts.isCurrentRateAlert && (
            <Typography.P comp="x-small" fontColor="#202020" style={{ marginBottom: "5px" }}>
              {rate.product.name}
            </Typography.P>
          )}
          <Stack direction="row" alignItems="center" spacing={"4px"} style={{ height: "30px" }}>
            <div style={{ display: "flex", height: "22px", padding: "0px 10px", backgroundColor: "#66CFDC", border: "1px solid #66CFDC", borderRadius: "50px" }}>
              <Typography.P comp="hl-pill" fontColor="#FFFFFF">
                {rate.rateType}
              </Typography.P>
            </div>
            <div style={{ display: "flex", height: "22px", padding: "0px 10px", backgroundColor: "#66CFDC", border: "1px solid #66CFDC", borderRadius: "50px" }}>
              <Typography.P comp="hl-pill" fontColor="#FFFFFF">
                {rate.repaymentType}
              </Typography.P>
            </div>
          </Stack>
        </Stack>
        {rate.interestRate ? (
          <Stack direction="row" style={{ width: "50%" }}>
            <Stack style={{ width: "50%" }}>
              <Typography.P comp="hl-rate">{(rate.interestRate * 100).toFixed(2)}%</Typography.P>
              <Typography.P comp="x-small" fontColor="#A6A6A6">
                interest
                <br />
                rate p.a.
              </Typography.P>
            </Stack>
            <Stack style={{ width: "50%" }}>
              <Typography.P comp="hl-rate">{(rate.comparisonRate * 100).toFixed(2)}%</Typography.P>
              <Typography.P comp="x-small" fontColor="#A6A6A6">
                comparison
                <br />
                rate p.a.
              </Typography.P>
            </Stack>
          </Stack>
        ) : (
          <Typography.P>Not Available</Typography.P>
        )}
      </Stack>
    )
  }

  const OfferSummary = ({ offer, offerType }: { offer: any; offerType: "Stay" | "Go" }) => {
    let offerInstance: any = null
    if (offer.landingPageUrl) {
      (offer.properties as any[]).map((p, i) => {
        (p.loans as any[]).map((l, j) => {
          if (!offerInstance && l.offer) {
            offerInstance = l.offer
            return
          }
        })
      })
    }

    let disclaimerRate = NaN;
    (offer.properties as any[]).map((p, i) => {
      (p.loans as any[]).map((l, j) => {
        if (l.product.name == "Keep revert rate*") {
          disclaimerRate = l.interestRate
        }
      })
    })

    let finspo_offer_id: any = offer.properties[0].loans[0].product.id
    {
      (offer.properties as any[]).some((p) => {
        return (p.loans as any[]).some((l) => {
          if (l.offer) {
            finspo_offer_id = l.offer.offerId
            return true
          }
          return false
        })
      })
    }

    return (
      <div style={{ marginBlock: "20px" }}>
        <Stack alignItems="center" style={{ marginBottom: "35px" }}>
          <LazyLoadImage src={providerImage(offer.properties[0].providerId)} alt={offer.properties[0].providerName} title={offer.properties[0].providerName} style={{ height: "38px", width: "56px", marginBottom: "10px" }} />
          <Typography.P comp="medium-bold">{offer.properties[0].providerName}</Typography.P>
          <Typography.P comp="hl-benefit" fontColor="#00AFC5" style={{ marginBlock: "10px" }}>
            ${Math.round(offer.savings).toLocaleString()}
          </Typography.P>
          <Typography.P>estimated saving over 5 years</Typography.P>
        </Stack>

        {(offer.properties as any[]).map((p, i) => {
          const rates: { [key: string]: any } = {}
          const purpose = jsonInput.properties[i].purpose == "OWNER_OCCUPIED" ? "Home" : "Investment";
          (p.loans as any[]).map((l, j) => {
            let rateType = l.rate.rateType
            rateType = rateType.charAt(0) + rateType.slice(1).toLowerCase()
            if (l.rate.rateType == "FIXED" && l.rate.fixedTerm) {
              rateType += " " + l.rate.fixedTerm.substring(1)
            }
            const repaymentType = l.rate.repaymentType === "INTEREST_ONLY" ? "IO" : "P&I"
            const key = rateType.concat(repaymentType)
            if (!(key in rates)) {
              rates[key] = {
                product: l.product,
                alerts: l.alerts,
                rateType: rateType,
                repaymentType: repaymentType,
                interestRate: l.interestRate,
                comparisonRate: l.comparisonRate,
              }
            }
          })
          return Object.values(rates).map((r, j) => {
            return <RateView rate={r} purpose={offer.properties.length > 1 ? purpose : null} isDetailView={false} />
          })
        })}

        {disclaimerRate > 0 && <RevertRateDisclaimer interestRate={disclaimerRate} />}

        <Stack justifyContent="center" alignItems="center">
          {(offer.finspoPanel && offerType !== "Stay") ? <RefinanceDialog
            providerId={offer.properties[0].providerId}
            providerName={offer.properties[0].providerName}
            offerId={finspo_offer_id}
            isFinspoPanelLender
            webhookProps={{
              offer: offer,
              properties: jsonInput["properties"],
              surveyId: store.getState().survey.surveyId
            }}
            source={source}
          /> : (offer.landingPageUrl && false) ? (
            <Clickable.Text
              comp={3}
              className="survey-button"
              style={{ marginTop: "30px", width: "100%" }}
              onClick={() => {
                const dataToCollect: { [key: string]: any } = { surveyId: store.getState().survey.surveyId, providerId: offer.properties[0].providerId, offerId: offerInstance.offerId, offerType: offerType }
                DataCollector.getInstance().addAction(DataCollectorAction.HL_SURVEY_GET_OFFER_SELECTED, dataToCollect)
                dataToCollect["landing_page_url"] = offer.landingPageUrl
                dataToCollect["offer"] = offer
                dataToCollect["source"] = source
                API.updateHLSurveyOutcome(dataToCollect)
                AppManager.getInstance().getOffer(offerType, offerInstance.offerId, offer, offer.properties[0].providerId, offer.properties[0].providerName)
              }}
            >
              Go to offer
            </Clickable.Text>
          ) : (offerType !== "Stay") ? <RefinanceDialog
            providerId={offer.properties[0].providerId}
            providerName={offer.properties[0].providerName}
            offerId={finspo_offer_id}
            webhookProps={{
              offer: offer,
              properties: jsonInput["properties"],
              surveyId: store.getState().survey.surveyId
            }}
            source={source}
          /> : null}
          <Clickable.Text
            comp={3}
            secondary
            className="survey-button"
            style={{ marginTop: "15px", width: "100%" }}
            onClick={() => {
              API.getHLOfferDetails({ surveyId: store.getState().survey.surveyId, offer: { offerType: offerType, ...offer }, properties: jsonInput.properties }, responseJson => {
                DataCollector.getInstance().addAction(DataCollectorAction.HL_SURVEY_OFFER_DETAILS_VIEWED, { surveyId: store.getState().survey.surveyId, page: "overview" })
                navigate("/home-loans/offer-details", {
                  state: {
                    progress: progress + 1,
                    jsonInput: jsonInput,
                    results: { ...offer, offerType: offerType },
                  },
                })
              })
            }}
          >
            Learn more
          </Clickable.Text>
        </Stack>
      </div>
    )
  }

  const TopDealsInMarket = () => {
    const majorOfferSavings = Math.round(results.majorOffer.savings)
    const challengerOfferSavings = Math.round(results.challengerOffer.savings)

    const majorOfferRateDifference = results.majorOffer.rateDifference * 100
    const challengerOfferRateDifference = results.challengerOffer.rateDifference * 100
    const rateGap = Math.min(...[majorOfferRateDifference, challengerOfferRateDifference].filter(v => v != null))

    return (
      <Stack>
        <Stack alignItems="center" style={{ marginTop: "-2.5px" }}>
          <Typography.H3 comp="hl-survey">Your deal vs market</Typography.H3>
        </Stack>

        {/* <ProgressBar progress={progress} /> */}

        <Stack direction="row" justifyContent="space-between" style={{ marginTop: "40px" }}>
          <Typography.P comp="hl-medium-bold-tight" style={{ width: "30%" }}>
            Top deals
            <br />
            in market
          </Typography.P>
          <Typography.P comp="hl-medium-bold-tight" fontColor="#00AFC5" style={{ width: "25%", textAlign: "right" }}>
            Estimated 5 year Savings
          </Typography.P>
          <Typography.P comp="hl-medium-tight" fontColor="#202020" style={{ width: "25%", textAlign: "right" }}>
            Estimated Rate Difference
          </Typography.P>
        </Stack>

        <div style={{ borderBottom: "1px solid #D2D2D2", marginBlock: "20px" }} />

        <Stack direction="row" justifyContent="space-between">
          <Typography.P comp="flat-18" style={{ width: "30%" }}>
            Major bank
          </Typography.P>
          <Typography.P comp="medium-bold" fontColor="#00AFC5" style={{ width: "25%", textAlign: "right" }}>
            ${majorOfferSavings.toLocaleString()}
          </Typography.P>
          <Stack direction="row" justifyContent="flex-end" alignItems="center" spacing={0.5} style={{ width: "25%" }}>
            {majorOfferRateDifference ? (
              <div style={{ height: "75%" }}>
                <LazyLoadImage src="/images/icon_lower.png" style={{ width: "20px", height: "20px" }} />
              </div>
            ) : null}
            <Typography.P comp="medium">{majorOfferRateDifference ? majorOfferRateDifference.toFixed(2) : "0"}%</Typography.P>
          </Stack>
        </Stack>

        <Stack direction="row" justifyContent="space-between" style={{ marginTop: "5px" }}>
          <Typography.P comp="flat-18" style={{ width: "30%" }}>
            Challenger
          </Typography.P>
          <Typography.P comp="medium-bold" fontColor="#00AFC5" style={{ width: "25%", textAlign: "right" }}>
            ${challengerOfferSavings.toLocaleString()}
          </Typography.P>
          <Stack direction="row" justifyContent="flex-end" alignItems="center" spacing={0.5} style={{ width: "25%" }}>
            {challengerOfferRateDifference ? (
              <div style={{ height: "75%" }}>
                <LazyLoadImage src="/images/icon_lower.png" style={{ width: "20px", height: "20px" }} />
              </div>
            ) : null}
            <Typography.P comp="medium">{challengerOfferRateDifference ? challengerOfferRateDifference.toFixed(2) : "0"}%</Typography.P>
          </Stack>
        </Stack>

        <Stack alignItems="center" spacing={1.25} style={{ height: "114px", backgroundColor: "rgba(254, 236, 242, 0.5)", padding: "15px", marginTop: "50px" }}>
          {Object.keys(results).length > 0 ? (
            <Stack direction="row" alignItems="center">
              <LazyLoadImage src={providerImage(jsonInput.properties[0].provider.id)} style={{ width: "56px" }} alt="" />
              <Typography.P comp="medium-bold">{jsonInput.properties[0].provider.name}</Typography.P>
            </Stack>
          ) : null}
          {results.majorOffer.savings >= 5000 || results.challengerOffer.savings >= 5000 ? (
            <Typography.P comp="medium-bold" fontColor="#E7527A">
              current deal is uncompetitive
            </Typography.P>
          ) : results.majorOffer.savings >= 1000 || results.challengerOffer.savings >= 1000 ? (
            <Typography.P comp="medium-bold" fontColor="#E7527A">
              current deal could be better
            </Typography.P>
          ) : null}
        </Stack>

        <Clickable.Text
          comp={3}
          className="survey-button"
          style={{ marginTop: "55px", height: "60px" }}
          onClick={() => {
            if (isLoggedIn) {
              if (results.isPartner) {
                navigate("/home-loans/notifying", {
                  state: {
                    jsonInput: jsonInput,
                    rateGap: rateGap,
                  },
                })
              } else {
                navigate("/home-loans/offers-summary", {
                  state: { ...props.location.state, progress: 5, results: undefined },
                })
              }
            } else {
              DataCollector.getInstance().addAction(DataCollectorAction.HL_SURVEY_CURRENT_DEAL_CONTINUED, { surveyId: store.getState().survey.surveyId })
              navigate("/home-loans/create-account", {
                state: {
                  progress: progress + 1,
                  jsonInput: jsonInput,
                  isPartner: results.isPartner,
                  rateGap: rateGap,
                },
              })
            }
          }}
        >
          Get a better deal
        </Clickable.Text>
        {/* {isLoggedIn && <Clickable.Text
          comp={3}
          className="survey-button"
          style={{ marginTop: "55px", height: "60px" }}
          onClick={() => {
            API.reviewCurrentHLDeal({ properties: jsonInput["properties"], rateGap: Math.min(...[majorOfferRateDifference, challengerOfferRateDifference].filter(v => v != null)) }, responseJson => {
              console.log(responseJson)
            })
          }}
        >
          Review your current deal
        </Clickable.Text>} */}

        <Disclaimer />
      </Stack>
    )
  }

  const OffersView = () => {
    const getStayOffersTitle = () => {
      const providers = new Set();
      (jsonInput.properties as any[]).map((p, i) => {
        providers.add(p.provider.name)
      })
      if (providers.size === 1) {
        return providers.values().next().value
      } else {
        return "Stay"
      }
    }

    return (
      <div>
        <Stack style={{ width: "-webkit-calc(100% + 38px)", marginLeft: "-19px", backgroundColor: "#4D4D4D", height: "56px" }} justifyContent="center">
          <Typography.P comp="medium-bold" fontColor="#FFFFFF" style={{ marginLeft: "15px" }}>
            {getStayOffersTitle()} offers
          </Typography.P>
        </Stack>
        {results.offers.stayOffers.length > 0 ? (
          <div>
            {(results.offers.stayOffers as any[]).map((o, i) => {
              return (
                <div>
                  <OfferSummary offer={o} offerType={"Stay"} />
                  {i < results.offers.stayOffers.length - 1 && <hr style={{ marginTop: "40px" }} />}
                </div>
              )
            })}
          </div>
        ) : (
          <Stack alignItems="center" style={{ marginTop: "20px", marginBottom: "-25px" }}>
            <LazyLoadImage src={providerImage(jsonInput.properties[0].provider.id)} alt={jsonInput.properties[0].provider.name} title={jsonInput.properties[0].provider.name} style={{ height: "38px", width: "56px", marginBottom: "10px" }} />
            <Typography.P comp="medium-bold">{jsonInput.properties[0].provider.name}</Typography.P>
            <Typography.P comp="hl-benefit" fontColor="#F9689C" style={{ marginBlock: "10px" }}>
              No offer
            </Typography.P>
          </Stack>
        )}
        <Stack style={{ width: "-webkit-calc(100% + 38px)", marginLeft: "-19px", backgroundColor: "#4D4D4D", height: "56px", marginTop: "60px" }} justifyContent="center">
          <Typography.P comp="medium-bold" fontColor="#FFFFFF" style={{ marginLeft: "15px" }}>
            Top market offers
          </Typography.P>
        </Stack>
        {results.offers.goOffers.length > 0 ? (
          (results.offers.goOffers as any[]).map((o, i) => {
            return (
              <div>
                <OfferSummary offer={o} offerType={"Go"} />
                {i < results.offers.goOffers.length - 1 && <div style={{ borderBottom: "1px solid #A6A6A6", width: "-webkit-calc(100% + 38px)", marginLeft: "-19px", marginTop: "40px" }} />}
              </div>
            )
          })
        ) : (
          <Stack style={{ textAlign: "center" }}>
            <Typography.P comp="hl-benefit" fontColor="#F9689C" style={{ marginTop: "40px", marginBottom: "20px" }}>
              No offer
            </Typography.P>
            <Typography.P comp="small">
              We were unable to find better offers
              <br />
              from across the market
            </Typography.P>
          </Stack>
        )}
        <Disclaimer />
      </div>
    )
  }

  const Overview = () => {
    let cashBackValue = 0;
    (results.properties as any[]).map((p, i) => {
      (p.loans as any[]).map((l, j) => {
        cashBackValue += l.cashback
      })
    })

    const products: any = {}
    const groupProducts: any = {};
    (results.properties as any[]).map((p, i) => {
      (p.loans as any[]).map((l, j) => {
        const offerIndex = { propertyIndex: i, loanIndex: j }
        if (!(l.product.id in groupProducts)) {
          products[l.product.id] = { product: l.product, hasVariable: false, hasFixed: false, hasFee: false }
          groupProducts[l.product.id] = {}
        }
        if (l.rate.rateType == "VARIABLE") {
          products[l.product.id].hasVariable = true
        } else if (l.rate.rateType == "FIXED") {
          products[l.product.id].hasFixed = true
        }
        if (l.fee) {
          products[l.product.id].hasFee = true
        }
        const key = l.rate.rateType.concat(l.rate.repaymentType).concat(l.rate.interestRate)
        groupProducts[l.product.id][key] = offerIndex
      })
    })

    return (
      <div style={{ marginBottom: "60px" }}>
        {cashBackValue > 0 && (
          <Stack style={{ backgroundColor: "#FBFCFC", padding: "12px 12px 20px 12px" }}>
            <Typography.P comp="medium-bold" fontColor="#E7527A">
              Cashback offer
            </Typography.P>
            <Stack direction="row" spacing={0.5}>
              <Typography.P comp="hl-cash-back" fontColor="#00AFC5">
                ${cashBackValue.toLocaleString()}
              </Typography.P>
              <Typography.P>{"cashback when you refinance"}</Typography.P>
            </Stack>
          </Stack>
        )}

        <div style={{ marginBlock: "40px" }}>
          {Object.keys(groupProducts).map((productId: string, i: number) => {
            const product = products[productId].product
            let showProductFeatures = true
            if (product.id.startsWith("00000")) {
              showProductFeatures = false
            }
            const hasVariable = products[productId].hasVariable
            const hasFixed = products[productId].hasFixed

            const hasFee = products[productId].hasFee
            const hasRepayments = product.addRepayments === "Y"
            const hasRedraw = (product.redrawFixed === "Y" && hasFixed) || (product.redrawVariable === "Y" && hasVariable)
            const hasOffset = (product.offsetFixed === "Y" && hasFixed) || (product.offsetVariable === "Y" && hasVariable)

            return (
              <Stack style={{ marginBottom: "50px" }}>
                <Typography.P comp="hl-medium-small-tight" fontColor="#4D4D4D">
                  {product.name}
                </Typography.P>
                <Stack alignItems="center">
                  <div style={{ width: "90%" }}>
                    {Object.values(groupProducts[productId]).map((offerIndex: any, j: number) => {
                      const l = results.properties[offerIndex["propertyIndex"]].loans[offerIndex["loanIndex"]]
                      const loanPurpose = l.rate.loanPurpose == "OWNER_OCCUPIED" ? "Home" : "Investment"
                      let rateType = l.rate.rateType
                      rateType = rateType.charAt(0) + rateType.slice(1).toLowerCase()
                      if (l.rate.rateType == "FIXED" && l.rate.fixedTerm) {
                        rateType = rateType + " " + l.rate.fixedTerm.substring(1)
                      }
                      const repaymentType = l.rate.repaymentType === "INTEREST_ONLY" ? "IO" : "P&I"
                      const r = {
                        product: l.product,
                        alerts: l.alerts,
                        rateType: rateType,
                        repaymentType: repaymentType,
                        interestRate: l.interestRate,
                        comparisonRate: l.comparisonRate,
                      }
                      return (
                        <div>
                          <RateView rate={r} purpose={results.properties.length > 1 ? loanPurpose : null} isDetailView={true} />
                          {l.product.name === "Keep revert rate*" && <RevertRateDisclaimer interestRate={l.interestRate} />}
                        </div>
                      )
                    })}
                    {showProductFeatures ? (
                      <div>
                        {/* {!hasFee && (
                        <Stack direction="row" alignItems="center" spacing={2} style={{ marginBlock: "5px" }}>
                          <LazyLoadImage src={"/images/icon_green_tick.png"} style={{ width: "20px" }} />
                          <Typography.P comp="small" fontColor="#797979">No ongoing fees</Typography.P>
                        </Stack>
                      )} */}
                        {hasRepayments && hasRedraw && (
                          <Stack direction="row" alignItems="center" spacing={2} style={{ marginBlock: "5px" }}>
                            <LazyLoadImage src={"/images/icon_green_tick.png"} style={{ width: "20px" }} />
                            <Typography.P comp="small" fontColor="#797979">
                              Make & redraw additional repayments
                            </Typography.P>
                          </Stack>
                        )}
                        {hasRepayments && !hasRedraw && (
                          <Stack direction="row" alignItems="center" spacing={2} style={{ marginBlock: "5px" }}>
                            <LazyLoadImage src={"/images/icon_green_tick.png"} style={{ width: "20px" }} />
                            <Typography.P comp="small" fontColor="#797979">
                              Make additional repayments
                            </Typography.P>
                          </Stack>
                        )}
                        {hasOffset && (
                          <Stack direction="row" alignItems="center" spacing={2} style={{ marginBlock: "5px" }}>
                            <LazyLoadImage src={"/images/icon_green_tick.png"} style={{ width: "20px" }} />
                            <Typography.P comp="small" fontColor="#797979">
                              Offset account
                            </Typography.P>
                          </Stack>
                        )}

                        {!hasRepayments && !hasRedraw && (
                          <Stack direction="row" alignItems="center" spacing={2} style={{ marginBlock: "5px" }}>
                            <LazyLoadImage src={"/images/icon_pink_cross.png"} style={{ width: "20px" }} />
                            <Typography.P comp="small" fontColor="#797979">
                              No additional repayments or redraw
                            </Typography.P>
                          </Stack>
                        )}
                        {hasRepayments && !hasRedraw && (
                          <Stack direction="row" alignItems="center" spacing={2} style={{ marginBlock: "5px" }}>
                            <LazyLoadImage src={"/images/icon_pink_cross.png"} style={{ width: "20px" }} />
                            <Typography.P comp="small" fontColor="#797979">
                              No redraw
                            </Typography.P>
                          </Stack>
                        )}
                        {!hasOffset && (
                          <Stack direction="row" alignItems="center" spacing={2} style={{ marginBlock: "5px" }}>
                            <LazyLoadImage src={"/images/icon_pink_cross.png"} style={{ width: "20px" }} />
                            <Typography.P comp="small" fontColor="#797979">
                              No offset account
                            </Typography.P>
                          </Stack>
                        )}
                      </div>
                    ) : null}
                  </div>
                </Stack>
              </Stack>
            )
          })}
        </div>
      </div>
    )
  }

  const SavingsView = () => {
    let costSavedPerYear = [0, 0, 0, 0, 0];
    (results.properties as any[]).map((p, i) => {
      (p.loans as any[]).map((l, j) => {
        (l.savings.perYear as any[]).map((v, k) => {
          costSavedPerYear[k] += v
        })
      })
    })

    let principalSaved = 0
    let interestSaved = 0
    let feesSaved = 0
    let cashBack = 0
    let depositInterest = 0;
    (results.properties as any[]).map((p, i) => {
      (p.loans as any[]).map((l, j) => {
        principalSaved += l.savings.principal
        interestSaved += l.savings.repayment
        feesSaved += l.savings.fees
        cashBack += l.savings.cashback
        depositInterest += l.savings.depositInterest
      })
    })

    const totalInterestSaved = interestSaved + depositInterest

    const minVal = Math.min(0, ...costSavedPerYear, principalSaved, totalInterestSaved, feesSaved, cashBack)
    const maxVal = Math.max(0, ...costSavedPerYear, principalSaved, totalInterestSaved, feesSaved, cashBack)

    // Min Scale: constant to ensure small values aren't over represnted with large bars
    const minScale = 500

    let range = maxVal - minVal
    if (range === 0) {
      range = Number.MIN_VALUE
    }
    const scaleAdjustment = maxVal / Math.max(range, minScale) - minVal / Math.max(range, minScale)
    const scale = Math.max(range, minScale) * scaleAdjustment
    const zeroInterceptScaled = Math.abs(minVal) / scale

    const ItemView = ({ text, value }: { text: string, value: number }) => {
      const infoColor = value >= 0 ? "#00AFC5" : "#E7527A"
      const info = (value >= 0 ? "$" : "-$") + Math.abs(Math.round(value)).toLocaleString()
      const barValue = value / scale
      const bar1Width = ((barValue >= 0 ? Math.abs(zeroInterceptScaled) : Math.abs(zeroInterceptScaled + barValue)) - 0.01) * 100 + "%"
      const bar2Width = (Math.abs(barValue) - 0.01) * 100 + "%"
      // console.log("barValue:", barValue)
      // console.log("s1, s2", scale, scale2)
      //console.log("zeroInterceptScaled:", zeroInterceptScaled)
      return (
        <div style={{ height: "30px", display: "flex", alignItems: "center", width: "100%" }}>
          <div style={{ width: "28.3%", display: "flex", justifyContent: "flex-end", marginRight: "5%" }}>
            <Typography.P comp="offer-detail">{text}</Typography.P>
          </div>
          <div style={{ height: "24px", display: "flex", width: "33.3%" }}>
            <div style={{ width: bar1Width, display: "flex" }} />
            {barValue > 0 ? <div style={{ backgroundColor: "#00AFC5", width: 2 }} /> : null}
            <div style={{ marginTop: "2px", height: "20px", width: bar2Width, display: "flex", backgroundColor: barValue >= 0 ? "#00AFC5" : "#E7527A" }} />
            {barValue < 0 ? <div style={{ backgroundColor: "#E7527A", width: 2 }} /> : null}
          </div>
          <div style={{ width: "33.3%", display: "flex", justifyContent: "flex-end" }}>
            <Typography.P fontColor={infoColor}>{info}</Typography.P>
          </div>
        </div>
      )
    }

    return (
      <div style={{ marginBottom: "100px" }}>
        <Stack justifyContent="center" style={{ height: "40px", backgroundColor: "#66CFDC", padding: "0px 10px", marginBottom: "15px" }}>
          <Typography.P comp="medium-bold" fontColor="#FFFFFF">
            Estimated Savings
          </Typography.P>
        </Stack>
        <Stack>
          <ItemView text="Year1" value={costSavedPerYear[0]} />
          <ItemView text="Year2" value={costSavedPerYear[1]} />
          <ItemView text="Year3" value={costSavedPerYear[2]} />
          <ItemView text="Year4" value={costSavedPerYear[3]} />
          <ItemView text="Year5" value={costSavedPerYear[4]} />
        </Stack>

        <div style={{ borderBottom: "1px solid #A6A6A6", marginTop: "15px", marginBottom: "10px" }} />

        <ItemView text="Principal^" value={principalSaved} />
        <ItemView text={"Interest" + (depositInterest > 0 ? "*" : "")} value={totalInterestSaved} />
        <ItemView text="Fees" value={feesSaved} />
        <ItemView text="Cashbacks" value={cashBack} />

        <div style={{ borderBottom: "1px solid #A6A6A6", marginTop: "15px", marginBottom: "10px" }} />

        <Stack direction="row" justifyContent="space-between" alignItems="center">
          <Typography.P comp="small-bold">5 year total</Typography.P>
          <Typography.P comp="large-bold" fontColor="#00AFC5">
            ${Math.round(results.savings).toLocaleString()}
          </Typography.P>
        </Stack>

        {principalSaved > 0 ? (
          <div style={{ marginTop: "20px" }}>
            <Typography.P comp="hl-medium-tight">^ With this offer, you could repay an additional ${Math.round(principalSaved).toLocaleString()} of your loan, over the next 5 years</Typography.P>
          </div>
        ) : null}
        {depositInterest > 0 ? (
          <div style={{ marginTop: "20px" }}>
            <Typography.P comp="hl-medium-tight">* Some home loan products within this offer do not feature an offset account. We assume you deposit these offset funds into a savings account and earn ${Math.round(depositInterest).toLocaleString()} of interest over the 5 years.</Typography.P>
          </div>
        ) : null}
      </div>
    )
  }

  const ComparisonView = () => {
    return <div>comparison view</div>
  }

  const IndexButtonView = (props: any) => (
    <Clickable.Text
      style={{ width: "50%", height: "56px", color: indexView !== props.index ? "#00AFC5" : null, backgroundColor: indexView !== props.index ? "#F2FBFC" : null }}
      // className={styles.nextButton}
      onClick={() => {
        DataCollector.getInstance().addAction(DataCollectorAction.HL_SURVEY_OFFER_DETAILS_VIEWED, { surveyId: store.getState().survey.surveyId, page: props.index == 1 ? "savings" : "overview" })
        setIndexView(props.index)
      }}
    >
      {props.children}
    </Clickable.Text>
  )

  const OfferDetails = () => {
    let offerInstance: any = null
    if (results.landingPageUrl) {
      (results.properties as any[]).map((p, i) => {
        (p.loans as any[]).map((l, j) => {
          if (!offerInstance && l.offer) {
            offerInstance = l.offer
            return
          }
        })
      })
    }

    return (
      <div>
        <Stack direction="row" justifyContent="space-between" alignItems="center" spacing={2}>
          <Stack>
            <Typography.P comp="medium-bold">{results.properties[0].providerName}</Typography.P>
            {/* {Array.from(productNames).map((name, i) => {
              return <Typography.P>{name}</Typography.P>
            })} */}
          </Stack>
          <LazyLoadImage src={providerImage(results.properties[0].providerId)} alt={results.properties[0].providerName} title={results.properties[0].providerName} style={{ height: "38px", width: "56px" }} />
        </Stack>

        <Stack direction="row" style={{ marginTop: "40px", marginBottom: "30px" }}>
          <IndexButtonView index={0}>Overview</IndexButtonView>
          <IndexButtonView index={1}>Savings</IndexButtonView>
          {/* <IndexButtonView index={2}>
            Comparison
          </IndexButtonView> */}
        </Stack>

        {indexView === 1 ? <SavingsView /> : indexView === 2 ? <ComparisonView /> : <Overview />}

        <Stack justifyContent="center" alignItems="center">
          {results.landingPageUrl && (
            <Clickable.Text
              comp={3}
              className="survey-button"
              style={{ marginTop: "30px", width: "100%" }}
              onClick={() => {
                const dataToCollect: { [key: string]: any } = { surveyId: store.getState().survey.surveyId, providerId: results.properties[0].providerId, offerId: offerInstance.offerId, offerType: results.offerType }
                DataCollector.getInstance().addAction(DataCollectorAction.HL_SURVEY_GET_OFFER_SELECTED, dataToCollect)
                dataToCollect["landing_page_url"] = results.landingPageUrl
                dataToCollect["offer"] = results
                dataToCollect["source"] = source
                API.updateHLSurveyOutcome(dataToCollect)
                AppManager.getInstance().getOffer(results.offerType, offerInstance.offerId, results, results.properties[0].providerId, results.providerName)
              }}
            >
              Go to offer
            </Clickable.Text>
          )}
          <Clickable.Text
            comp={3}
            secondary
            className="survey-button"
            style={{ marginTop: "15px", width: "100%" }}
            onClick={() => {
              window.history.back()
            }}
          >
            {"<"} Back
          </Clickable.Text>
        </Stack>

        <Disclaimer />
      </div>
    )
  }

  return (
    <div className="page-container">
      <div className="survey-container">
        {progress == 3 && <Congratulations />}
        {progress == 4 && <TopDealsInMarket />}
        {progress == 5 && Object.keys(results).length > 0 && <OffersView />}
        {progress == 6 && <OfferDetails />}
      </div>
    </div>
  )
}

export default Offers
